@tailwind base;
@tailwind components;
@tailwind utilities;
/* Add your other Tailwind imports or custom styles here */
@import url('https://fonts.googleapis.com/css2?family=Avenir+Next:wght@400;600&family=Muli:wght@400;500&display=swap');

body {
  font-family: 'Avenir Next', 'Muli', sans-serif;
}

.transition {
  transition: background-color 0.5s, color 0.5s, transform 0.5s;
}

input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

label[for="file"] {
  cursor: pointer;
}

label[for="file"] span {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 25px;
  background: white;
  color: #1f3545;
  font-size: 1rem;
  font-weight: 600;
  transition: background 0.3s ease-in-out;
}

label[for="file"] span:hover {
  background: #e0e0e0;
}
